<template>
  <product-variant-selection
    v-model="attributeSelection"
    v-bind="{
      allVariantsOutOfStock,
      attributes,
      attributeSelectionLabels,
      error,
      getAttrOptionAvailable,
      isComingSoon,
      pending,
      product,
      productInventory,
      selectedVariant,
      showNotifyMe,
      showSizeAndFit,
      variants,
    }"
    @select-color="(...e) => $emit('selectColor', ...e)"
    @select-size="(...e) => $emit('selectSize', ...e)"
    @open-size-guide="$emit('openSizeGuide')"
  />

  <slot name="above-cta" />
  <div class="b-t b-grey-70 pb-8 pt-4 space-y-4 !b-transparent !py-5 ">
    <component
      :is="(product && !isSelectedProductOutOfStock && !allVariantsOutOfStock)
        ? BaseSticky : 'div'"
      name="productDetailsCta"
    >
      <div class="flex gap-4 wrap gap-x-1 ">
        <template
          v-if="isComingSoon
            || ((selectedVariant && isSelectedProductOutOfStock)
              || allVariantsOutOfStock)"
        >
          <product-notify-me
            v-if="showNotifyMe && selectedVariant?.notifyMe || (isSomeVariantNotifyMe && !pending && !selectedVariant)"
            :attribute-selection-labels="attributeSelectionLabels"
            :attribute-selection="attributeSelection"
            :is-coming-soon="isComingSoon"
            :product-color="productColor"
            :product="product"
            :selected-variant="isComingSoon ? { id: product.id } : selectedVariant"
            :size="ctaSize"
            class="grow !w-2/3"
            @notify="$emit('notify', $event)"
            @no-variant-selected="$emit('noVariantSelected')"
          />
          <vf-button
            v-else
            :size="ctaSize"
            :loading="pending"
            class="w-2/3 grow"
            disable-success-icon
            disabled
          >
            {{ comingSoonLabel || $t.outOfStock }}
          </vf-button>
        </template>
        <template v-else>
          <vf-button
            id="pdp-add-to-cart"
            type="submit"
            :variant="signInToBuyCtaTheme ? 'themable' : 'primary'"
            :theme="signInToBuyCtaTheme"
            :size="ctaSize"
            :disabled="!pending && (addToCartLoading || checkoutCtaLoading)"
            :loading="addToCartLoading && !checkoutCtaLoading"
            aria-live="polite"
            class="w-2/3 grow"
            :class="{ '!w-1/3': showCheckout, 'c-white': signInToBuyCtaTheme }"
            @click="$emit('addToCart')"
          >
            {{ isSignInToBuy ? $t.signInToBuy : $t.addToCart }}
          </vf-button>
          <vf-button
            v-if="showCheckout"
            :disabled="!pending && (addToCartLoading || checkoutCtaLoading)"
            :loading="checkoutCtaLoading"
            :size="ctaSize"
            variant="secondary"
            class="w-2/3 grow"
            :class="{
              '!w-1/3': showCheckout,
            }"
            @click="$emit('addToCartAndCheckout')"
          >
            {{ $t.checkout }}
          </vf-button>
          <client-only>
            <checkout-apple-pay-button
              v-if="isApplePayEnabled"
              :size="ctaSize"
              class="w-2/3 grow"
              @click="$emit('triggerApplePaySession')"
            />
            <template #fallback>
              <span class="hidden" />
            </template>
          </client-only>
        </template>
        <vf-button
          v-if="showTryItOnCtaOnPDP && productHasTryItOnAsset"
          id="pdp-try-it-on"
          :variant="ctaVariant"
          class="w-2/3 grow"
          :size="ctaSize"
          :aria-label="$t.tryItOn"
          @click="$emit('tryItOn')"
        >
          <vf-icon name="try-it-on" size="md" class="mr-2" />
          {{ $t.tryItOn }}
        </vf-button>
        <vf-button
          v-if="showFindInStore && !isComingSoon"
          :variant="ctaVariant"
          :size="ctaSize"
          class="w-2/3 grow"
          @click="$emit('findInStore', attributeSelectionLabels)"
        >
          <vf-icon v-if="findInStoreCta.showIcon" name="location" size="md" class="mr-2" />
          {{ $t.findInStore }}
        </vf-button>
        <vf-button
          v-if="showFavoriteCta"
          :variant="isFavorite ? favoriteCta.variantSelected : ctaVariant"
          :size="ctaSize"
          :loading="isFavoritePending"
          :disabled="favorites.error"
          class="grow "
          :aria-label="isFavorite ? $t.removeFromFavorites : $t.favorites"
          :data-test-id="`pdp-${isFavorite ? 'remove' : 'add'}-favorites-cta`"
          @click="$emit('toggleFavorites', productColor?.label)"
        >
          <vf-icon
            :name="isFavorite ? 'heart-filled' : 'heart'"
            :size="favoriteCta.iconSize"
            :class="{ 'c-brand-2': isFavorite }"
          />
          <span v-if="!favoriteCta.iconOnly" class="ml-2">{{ $t.favorites }}</span>
        </vf-button>
      </div>
    </component>

    <slot name="below-cta" />
  </div>
</template>

<script lang="ts" setup>
import { BaseSticky } from '#components'
import type { BaseNotification } from '#types/notification'
import type { Inventory, Product, ProductAttributesOptionsColor, ProductVariants } from '#root/api/clients/product/data-contracts'
import type { AttributeSelection, OptionGroups, ProductAttribute, ProductAttributeType, SelectedVariant } from '#types/product'

const props = defineProps<{
  addToCartLoading: boolean
  allVariantsOutOfStock?: boolean
  attributes: (ProductAttribute & { groupedOptions: OptionGroups[] })[]
  checkoutCtaLoading: boolean
  error: boolean
  isComingSoon: boolean
  isFavorite: boolean
  isFavoritePending: boolean
  isSomeVariantNotifyMe: boolean
  isSelectedProductOutOfStock?: boolean
  isSignInToBuy: boolean
  pending: boolean
  product: Product
  productInventory: Inventory | null
  selectedVariant: SelectedVariant | null
  showCheckout: boolean
  showFindInStore: boolean
  showNotifyMe: boolean
  showSizeAndFit: boolean
  variants: ProductVariants
}>()

const emit = defineEmits<{
  addToCart: []
  addToCartAndCheckout: []
  findInStore: [attributeSelectionLabels: Record<string, string>]
  notify: [notification: BaseNotification]
  noVariantSelected: []
  openSizeGuide: []
  selectColor: [id: string, url: string, optionLabel: string]
  selectSize: [type: Exclude<ProductAttributeType, 'color'>, value: string, label: string]
  toggleFavorites: [productColor?: string]
  triggerApplePaySession: []
  tryItOn: []
}>()

const attributeSelection = defineModel<AttributeSelection>({ required: true })
const favorites = useFavoritesStore()
const { allowApplePayOrderOnPDP, showTryItOnCtaOnPDP, catalogFavorites } = useFeatureFlags()
const { favoriteCta, ctaSize, ctaVariant, findInStoreCta, signInToBuyCtaTheme } = useAppConfig().pages.pdp.details

const route = useRoute()

const getAttrOptionAvailable = (type: ProductAttributeType, value: string) => {
  // Attribute types except a current type and 'color'
  const otherAttributeTypes = props.attributes
    .filter((attribute) => !['color', type].includes(attribute.type))
    .map((attribute) => attribute.type)

  if (otherAttributeTypes.every((attributeType) => attributeSelection.value[attributeType])) {
    // If all other attribute types are selected, find the current option's variant
    const currentVariant = props.variants.find((variant) =>
      otherAttributeTypes.every((attributeType) =>
        variant.attributes[attributeType] === attributeSelection.value[attributeType])
        && variant.attributes[type] === value)

    return currentVariant?.productInventoryState === 'InStock'
  }

  // When the attribute option has other unselected attribute types, it's available
  return true
}

const isApplePayEnabled = computed(() => allowApplePayOrderOnPDP && window.ApplePaySession)

const productColor = computed(() =>
  props.product.attributes.reduce((colorObject: ProductAttributesOptionsColor | null, attribute) =>
    attribute.type === 'color' && !colorObject
      ? attribute.options.find((color) => color.id === props.product.id) as ProductAttributesOptionsColor
      : colorObject, null)
)

const comingSoonLabel = computed(() =>
  props.isComingSoon ? (props.product.badge || props.product.eyebrow)?.label : undefined
)

const showFavoriteCta = computed(() =>
  props.selectedVariant?.notifyMe && props.showNotifyMe
    ? (!favoriteCta.hideWhenNotifyMe && catalogFavorites)
    : catalogFavorites
)

const attributeSelectionLabels = computed(() =>
  props.product.attributes.reduce((labels: Record<string, string>, { options, type }) => ({
    ...labels,
    [type]: type === 'color'
      ? productColor.value?.label
      : options.find((option) => option.value === attributeSelection.value[type])?.label
  }), {}) as ProductVariants[number]['attributes']
)

const tryItOnProducts = ref()

if (showTryItOnCtaOnPDP) {
  try {
    const { data } = await useApi().internal.getStaticPage('vyking-products')
    tryItOnProducts.value = data.value
  }
  catch { tryItOnProducts.value = [] }
}

const productHasTryItOnAsset = computed(
  () => props.product.id && tryItOnProducts.value?.includes(props.product.id.toLowerCase())
)

if (route.path.includes('vyking') && productHasTryItOnAsset.value) emit('tryItOn')
</script>
