<template>
  <component
    :is="to ? BaseLink : BaseButton"
    v-style:w="width"
    :to
    class="flex"
    :style="{ 'aspect-ratio': config.thumbnail.rectangle.width / config.thumbnail.rectangle.height }"
    :aria-current="active"
    data-test-id="vf-color-picker"
  >
    <vf-loader v-if="loading" class="absolute z-overlay h-20" />
    <vf-color-swatch
      v-bind="{ unavailable, lazy }"
      :thumbnail="getImageTransformations(thumbnail, config.thumbnail.rectangle)"
      class="full duration"
      :class="{ 'b b-black': active }"
    >
      <span class="sr-only">{{ name }}</span>
      <span v-if="unavailable" class="absolute-0 bg-cross" style="background-color: #ffffff99;" />
    </vf-color-swatch>
  </component>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import type { Responsive } from '#types/common'
import type { SizesSubUnion } from '#types/sizes'

const { size } = withDefaults(
  defineProps<{
    /**
     * ColorPicker will be a link if this prop is provided
     */
    to?: string
    /**
     * Source for color thumbnail image
     */
    thumbnail?: string
    /**
     * Defines the size of the color picker
     */
    size?: SizesSubUnion<'sm' | 'md' | 'lg'> | Responsive<SizesSubUnion<'sm' | 'md' | 'lg'>>
    /**
     * Unavailable means the variant is out of stock, it will appear disabled but remain selectable
     */
    unavailable?: boolean
    /**
     * Whether color is selected
     */
    active?: boolean
    /**
     * Whether to lazy load the thumbnail image
     */
    lazy?: boolean
    /**
     * Whether the color picker is a loading state
     */
    loading?: boolean
    /**
     * Required color name for screen reader
     */
    name: string
  }>(),
  {
    size: 'md',
    lazy: true
  }
)

const config = useAppConfig().components.vf.colorPicker

const width = config.rectangle.width && replaceValues<Responsive, Responsive>(
  isObject(size) ? size : { sm: size },
  config.rectangle.width
)
</script>
