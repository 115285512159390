<template>
  <base-radio
    v-bind="{ value, name, disabled }"
    v-model="modelValue"
    class="gap-x-2 align-top i-flex"
    :class="{ 'cursor-pointer': !disabled }"
    data-test-id="vf-radio"
  >
    <span
      class="h-3 w-3 shrink-0 rounded-full ring ring-inset duration peer-focus-visible:outline-auto"
      :class="[
        disabled ? 'ring-grey-50'
        : invalid ? (variant ? 'ring-red-90' : 'ring-red-30')
          : (variant ? 'ring-white' : 'ring-black'),
      ]"
      style="padding: 2px"
      data-test-id="vf-radio-input"
    >
      <span
        class="block full rounded-full transition-opacity"
        :class="[
          disabled ? 'bg-grey-50' : (variant ? 'bg-white' : 'bg-black'),
          { 'op-0': modelValue !== value },
        ]"
      />
    </span>
    <span
      v-if="$slots.default"
      class="w-full transition-colors"
      :class="[
        disabled ? (variant ? 'c-grey-60' : 'c-grey-30')
        : invalid ? (variant ? 'c-red-90' : 'c-red-30')
          : (variant ? 'c-white' : 'c-black'),
      ]"
      data-test-id="vf-radio-label"
    >
      <!-- @slot Default slot mostly meant for label -->
      <slot />
    </span>
  </base-radio>
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Equal to native 'value' attribute of input:radio
   */
  value: string
  /**
   * Equal to native 'name' attribute of input:radio
   */
  name?: string
  /**
   * Defines disabled state. Means radio button becomes inactive
   */
  disabled?: boolean
  /**
   * Defines invalid state
   */
  invalid?: boolean
  /**
   * Defines variant
   */
  variant?: 'inverse'
}>()

const modelValue = defineModel<string>()
</script>
