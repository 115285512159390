<template>
  <div>
    <p v-if="notification.type === 'success'" class="c-green-30">
      {{ notification.message }}
    </p>
    <base-form
      v-else
      ref="formRef"
      :form
      data-test-id="form-notify-me"
      @submit="submit"
    >
      <div class="space-y-3">
        <vf-form-error v-if="notification.type === 'error'">
          {{ notification.message }}
        </vf-form-error>
        <vf-form-field
          v-slot="{ invalid }"
          name="phone"
          :rule="[validateRequired($t.mobileNumber), validatePhone(form.phoneCode)]"
        >
          <input-phone
            v-model="form.phone"
            v-model:code="form.phoneCode"
            :disabled="loading"
            :invalid="invalid"
            required
            use-country-code
            style="border-radius: 0;"
          />
        </vf-form-field>
        <vf-form-field v-slot="{ invalid }" name="policy" :rule="validateRequired('', $t.validators.requiredCheckbox)">
          <vf-checkbox v-model="form.policy" :disabled="loading" :invalid="invalid">
            {{ $t.notifyOutOfStockPolicy }}
          </vf-checkbox>
        </vf-form-field>
        <vf-button type="submit" class="w-full" :loading="loading">
          {{ $t.notifyMe }}
        </vf-button>
      </div>
    </base-form>
  </div>
</template>

<script lang="ts" setup>
import type { Product, ProductAttributesOptionsColor } from '#root/api/clients/product/data-contracts'
import type { BaseNotification } from '#types/notification'
import type { AttributeSelection } from '#types/product'

const props = defineProps<{
  product: Product
  attributeSelection: AttributeSelection
  attributeSelectionLabels: Record<string, string>
  productColor: ProductAttributesOptionsColor | null
  selectedVariant: { id: string } | null
}>()

const countryCode = useCountryCode()
const { validatePhone, validateRequired } = useLocalizedValidators()
const { $gtm, $t } = useNuxtApp()

const formRef = ref()

const form = reactive({
  phone: '',
  phoneCode: getPhoneCodeByCountryCode(countryCode),
  policy: false
})

const notification = reactive<BaseNotification>({
  type: '',
  message: ''
})

const loading = ref(false)

const submit = async () => {
  formRef.value?.validate()

  if (!props.selectedVariant) return

  $gtm.push('pdpPage.onNotifyMe', `${props.product.id} - ${props.productColor?.label}: ${Object.values(props.attributeSelection).join(':')}`)
  loading.value = true
  notification.type = ''

  try {
    const backInStock = (props.product.badge?.id || props.product.eyebrow?.id)?.toUpperCase() !== 'COMING SOON'

    await useApi().consumer.$notifyMobile({
      mobile: `+${form.phoneCode}${form.phone}`,
      productId: props.selectedVariant.id,
      backInStock
    })

    notification.type = 'success'
    notification.message = $t.notifyMeFormSuccess
  }
  catch (e) {
    if (e instanceof Error) {
      notification.type = 'error'
      notification.message = e.message
    }
  }
  finally {
    loading.value = false
  }
}
</script>
