<template>
  <div class="relative f-col gap-6">
    <vf-checkbox
      v-for="({ id, items, label }, i) in filter.options"
      :key="id + i"
      v-model="model"
      :value="id"
      class="flex-row-reverse between"
    >
      <span class="flex gap-1">
        <span class="line-clamp-3">
          {{ label }}
        </span>
        <span class="c-grey-40">
          ({{ items }})
        </span>
      </span>
    </vf-checkbox>
    <div v-if="loading" class="absolute-0 bg-white op-50" />
  </div>
</template>

<script lang="ts" setup>
import type { CheckboxFilter } from '#types/filters'

defineProps<{
  filter: CheckboxFilter
  loading?: boolean
}>()

const model = defineModel<string[]>()
</script>
