<template>
  <section v-if="colorAttribute" class="b-t b-grey-80 pt-5" data-test-id="color-groups" data-scroll-el="colorPickers">
    <div
      v-for="({ options }, i) in colorAttribute.groupedOptions"
      :key="i"
      class="grid gap-1"
      style="grid-template-columns: repeat(auto-fill, minmax(4.1875rem, 1fr));"
      data-test-id="color-groups-item"
    >
      <vf-color-picker
        v-for="{ available, id, label: optionLabel, swatch, url } in options"
        :key="optionLabel"
        :thumbnail="swatch?.image"
        :active="product.id === id"
        :loading="pending && selectedProductId === id"
        :unavailable="!(showNotifyMe && product.notifyMe) && isComingSoon && !available && !pending"
        :name="optionLabel"
        class="!w-full"
        @click="selectNewColor(id, url, optionLabel)"
      />
    </div>
  </section>
  <template v-if="!isComingSoon">
    <section
      v-if="(showNotifyMe && product.notifyMe) || (!allVariantsOutOfStock || pending)"
      class="mt-6 <md:mt-8"
      data-test-id="size-groups"
    >
      <div class="flex between">
        <h2>{{ $t.selectSize }}</h2>
        <base-button v-if="product.sizeChart" id="pdp-size-chart" class="underlined" @click="$emit('openSizeGuide')">
          {{ $t.sizeChart }}
        </base-button>
      </div>
      <div class="relative z-2 mt-3 h-11 flex gap-x-1 between <md:mt-5">
        <base-form-field
          v-for="({ options, label, type }, i) in sizeAttributes"
          :key="type"
          v-slot="{ attrs: { onChange }, invalid }"
          :name="type"
          :rule="validateRequired()"
        >
          <vf-size-select
            ref="sizeSelectRef"
            v-model="attributeSelection[type]"
            :options="options"
            :label="`${$t.select} ${label}`"
            :invalid="error && invalid"
            class="grow transition-width"
            :class="{
              'w-1/2': !sizeSelectRef?.[i]?.opened && sizeAttributes.length > 1,
              'absolute-0 w-full z-1': sizeSelectRef?.[i]?.opened && i < sizeAttributes.length - 1,
              'absolute right-0 top-0 w-full z-1':
                sizeSelectRef?.[i]?.opened && i === sizeAttributes.length - 1,
            }"
            :data-test-id="`${type}-groups-item`"
            :data-scroll-el="`${type}Pickers`"
            @change="onChange"
          />
        </base-form-field>
      </div>
      <vf-form-error v-if="error && sizeAttributes.some(({ type }) => !attributeSelection[type])" class="mt-2">
        {{ $t.selectSizeError }}
      </vf-form-error>
    </section>
  </template>
</template>

<script lang="ts" setup>
import type { VfSizeSelect as VfSizeSelectType } from '#components'
import type { InventoryData, Product, ProductVariants } from '#root/api/clients/product/data-contracts'
import type { AttributeSelection, OptionGroups, ProductAttribute, ProductAttributeType } from '#types/product'

const props = defineProps<{
  allVariantsOutOfStock?: boolean
  attributes: (ProductAttribute & { groupedOptions: OptionGroups[] })[]
  error?: boolean
  getAttrOptionAvailable: (type: ProductAttributeType, value: string) => boolean
  isComingSoon?: boolean
  pending?: boolean
  product: Product
  productInventory: InventoryData | null
  showNotifyMe: boolean
  variants: ProductVariants
}>()

const emit = defineEmits<{
  selectColor: [id: string, url: string, optionLabel: string]
  selectSize: [type: Exclude<ProductAttributeType, 'color'>, value: string, label: string]
  openSizeGuide: []
}>()

const { $t } = useNuxtApp()

const attributeSelection = defineModel<AttributeSelection>({ required: true })
const sizeSelectRef = ref<InstanceType<typeof VfSizeSelectType>[]>()
const colorAttribute = computed(() => props.attributes.find(({ type }) => type === 'color'))
const sizeAttributes = computed(() => (props.attributes.filter(({ type }) => type !== 'color') || [])
  .sort(({ type }) => type === 'size' ? -1 : 1)
  .map(({ type, options, ...restAttribute }) => {
    const isOtherAttributeSelected = props.attributes.filter((attribute) => ![type, 'color'].includes(attribute.type))
      .every((attribute) => attributeSelection.value[attribute.type])

    return {
      ...restAttribute,
      type,
      options: options.map((option) => {
        let quantity = 0
        if (isOtherAttributeSelected) {
          const variantId = props.variants.find(({ attributes }) =>
            Object.entries({ ...attributeSelection.value, [type]: option.value })
              .every((selection) => attributes[selection[0]] === selection[1])
          )?.id || ''
          quantity = props.productInventory?.variants[variantId]?.quantity || 0
        }
        else {
          const currentOptionQuantities = props.variants
            .filter(({ attributes }) => attributes[type] === option.value)
            .map(({ id }) => props.productInventory?.variants[id]?.quantity || 0)
          quantity = Math.max(...currentOptionQuantities)
        }

        return {
          ...option,
          available: option.available && props.getAttrOptionAvailable(type, option.value),
          badge: quantity === 0
            ? props.product.notifyMe ? $t.notifyMe : $t.outOfStock
            : quantity <= 5 ? $t.lowInventory : ''
        }
      })
    }
  })
)

const selectedProductId = ref()

const selectNewColor = (id, url, optionLabel) => {
  selectedProductId.value = id
  emit('selectColor', id, url, optionLabel)
}
</script>
