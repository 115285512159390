<template>
  <base-toggle
    v-bind="{ value, disabled, required }"
    v-model="modelValue"
    class="gap-x-2 align-top i-flex"
    :class="{ 'cursor-pointer': !disabled }"
    data-test-id="vf-checkbox"
  >
    <span
      class="h-3 w-3 shrink-0 ring ring-inset duration peer-focus-visible:outline-auto"
      :class="[
        disabled ? 'ring-grey-50'
        : invalid ? (variant ? 'ring-red-90' : 'ring-red-30')
          : (variant ? 'ring-white' : 'ring-black'),
      ]"
      style="padding: 2px"
      data-test-id="vf-checkbox-icon"
    >
      <span
        class="block full transition-opacity"
        :class="[
          disabled ? 'bg-grey-50' : (variant ? 'bg-white' : 'bg-black'),
          { 'op-0': !checked },
        ]"
      />
    </span>
    <span
      v-if="$slots.default"
      class="transition-colors"
      :class="[
        disabled ? (variant ? 'c-grey-60' : 'c-grey-30')
        : invalid ? (variant ? 'c-red-90' : 'c-red-30')
          : (variant ? 'c-white' : 'c-black'),
        { 'text-xs': size === 'sm' },
      ]"
      style="margin-top: -0.1em;"
      data-test-id="vf-checkbox-label"
    >
      <slot />
      <span v-if="required" aria-hidden="true"> *</span>
    </span>
  </base-toggle>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  /**
   * Sets the value of the checkbox
   */
  value?: string
  /**
   * Determines the size of the checkbox and the amount of spacing between checkbox and label
   */
  size?: 'sm' | 'md'
  /**
   * Sets the disabled state of the checkbox
   */
  disabled?: boolean
  /**
   * Sets the invalid state of the checkbox
   */
  invalid?: boolean
  /**
   * Defines if the checkbox input is required for accessibility purposes
   */
  required?: boolean
  /**
   * Defines variant
   */
  variant?: 'inverse'
}>(), {
  size: 'md'
})

const modelValue = defineModel<boolean | string[]>()
const checked = computed(() =>
  Array.isArray(modelValue.value) && props.value ? modelValue.value.includes(props.value) : modelValue.value
)
</script>
