<template>
  <base-popover
    ref="popoverRef"
    v-slot="{ toggle, open, close, opened }"
    :offset="-1"
    placement="bottom-start"
    match-reference-width
    data-test-id="vf-size-select"
  >
    <div
      :aria-expanded="opened"
      :aria-label="label"
      :aria-controls="id"
      aria-haspopup="listbox"
      role="combobox"
      tabindex="0"
      class="h-11 w-full flex items-center b b-grey-80 bg-white divide-x divide-grey-80"
      :class="{
        'z-2': opened,
        'cursor-pointer': !disabled,
        'b-grey-60 divide-grey-60 c-grey-60': disabled,
        'b-red-30 divide-red-30 c-red-30': invalid,
      }"
      @click="e => !disabled && toggle(e)"
      @keydown.enter="e => !disabled && open(e)"
      @keydown.space.prevent="e => !disabled && open(e)"
      @keydown.down.prevent="e => !disabled && open(e)"
    >
      <span class="pointer-events-none grow truncate px-3 text-left">
        {{ opened ? label : (selectedLabel || label) }}
      </span>
      <div class="pointer-events-none h-full w-11 flex center">
        <vf-icon :name="opened ? 'close' : 'chevron'" dir="down" size="md" />
      </div>
    </div>
    <base-popover-content
      v-if="options?.length"
      :id
      ref="listRef"
      role="listbox"
      enter-from-class="op-0"
      leave-to-class="op-0"
      class="z-1 overflow-auto bg-white shadow-lg ring ring-grey-80 ring-inset transition divide-y divide-grey-80"
      style="max-height: 26rem;"
      data-test-id="vf-size-select-content"
      @keydown.esc="close"
      @keydown.enter="e => e.target.click()"
      @keydown.tab.prevent="e => e.target.click()"
      @keydown.space.prevent="e => e.target.click()"
      @keydown.up.prevent="e => (e.target.previousElementSibling || listRef?.$el.lastElementChild).focus()"
      @keydown.down.prevent="e => (e.target.nextElementSibling || listRef?.$el.firstElementChild).focus()"
    >
      <div
        v-for="({ label: optionLabel, badge, value, available }, i) in options"
        :key="i"
        :aria-selected="modelValue === value"
        role="option"
        tabindex="0"
        class="h-11 flex cursor-pointer items-center p-3 between"
        :class=" {
          'ring ring-black ring-inset': modelValue === value,
          'c-grey-30': !available,
        }"
        @click="selectOption(value)"
      >
        <span>{{ optionLabel }}</span>
        <span>{{ badge }}</span>
      </div>
    </base-popover-content>
  </base-popover>
</template>

<script lang="ts" setup>
import type { BaseOption } from '#types/product'
import type { BasePopover as Popover, BasePopoverContent as PopoverContent } from '#components'

const props = defineProps<{
  /**
   * Defines label for select
   */
  label: string
  /**
   * Defines list of options
   */
  options?: (BaseOption & { badge?: string })[]
  /**
   * Defines disabled state
   */
  disabled?: boolean
  /**
   * Defines invalid state
   */
  invalid?: boolean
}>()

const emits = defineEmits<{ change: [value: string] }>()

const modelValue = defineModel<string>()
const id = useId()
const popoverRef = ref<InstanceType<typeof Popover>>()
const listRef = ref<InstanceType<typeof PopoverContent>>()

const selectedLabel = computed(() => props.options?.find(({ value }) => value === modelValue.value)?.label)

const selectOption = (value: string) => {
  modelValue.value = value
  popoverRef.value?.close()
  emits('change', value)
}

watch(() => popoverRef.value?.opened, () => {
  if (popoverRef.value?.opened) nextTick(() => listRef.value?.$el.firstElementChild?.focus())
})

defineExpose({
  opened: computed(() => popoverRef.value?.opened)
})
</script>
